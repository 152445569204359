<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Просмотр отзыва</strong>
    </div>
    <div class="card">
      <div class="card-body">
        <a-form-model>
          <a-form-model-item label="Дата" disabled>
            <a-input :value="review.created_at | getFormattedDate" disabled />
          </a-form-model-item>
          <a-form-model-item label="Оценка">
            <a-input :value="usability[review.rating]" disabled />
          </a-form-model-item>
          <a-form-model-item label="Комментарий">
            <a-textarea v-model="review.comment" :rows="4" disabled/>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import usability from '@/entities/usability'

export default {
  name: 'id',

  data: () => ({
    usability,
    review: {
      created_at: '',
      rating: '',
      comment: '',
    },
  }),

  async created() {
    this.review = (await this.$services.get(`admin/statistic_rating/${this.$route.params.id}`)).data.data.rating
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
}
</style>
